<template>
    <v-layout class="page" fill-height>
        <div class="box-container">
            <div class="conBox"> 
                <!--검색&등록버튼-->
                <div class="srhTop">
                    <div :class="'srhBox '+srhBoxFocus">
                        <label class="sel">
                            <select v-model="APIList.APIReq.searchType" @change="APIList.APIReq.searchQuery = '';">
                                <option v-for="(item, i) in searchItems" :key="i" :value="item.value">{{ item.name }}</option>
                            </select>
                        </label>
                        <label class="tex">
                            <input type="text" :placeholder="$t('common.msg.search.query')" v-model="APIList.APIReq.searchQuery" @focus="srhBoxFocus='focusOn'" @blur="srhBoxFocus=''" @keyup.enter="APIList.APIReq.pageNo=1;getList();">
                        </label>
                        <button class="srhBtn btn solid" v-ripple @click="search"><i class="micon">search</i></button>
                    </div>
                </div>
                <!--//검색&등록버튼-->
                <!--리스트/상세-->
                <div class="divBox">
                    <!--체크박스-->
                    <div class="checkboxArea">
                        <div>
                            <span class="labelText">{{ $t('page.transfer.list.text') }}</span>
                            <v-checkbox v-model="APIList.APIReq.onlyReqst" @change="chgOnlyReqst" color="indigo" label="" value="true" hide-details></v-checkbox>
                        </div>
                    </div>
                    <!--//체크박스-->
                    <div class="borderWrap">
                        <div class="tblScroll tblFixed" ref="scrollContainer" @scroll="handleScroll">
                            <table class="tbl" id="gd_p_list">
                                <caption>환자 이관신청 관리 목록</caption>
                                <colgroup>
                                    <col style="width:18%">
                                    <col style="width:15%">
                                    <col style="width:18%">
                                    <col style="width:18%">
                                    <col style="width:15%">
                                    <col >
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th style="cursor:pointer;" v-for="(item, i) in header" :key="i" @click="setSortType(`${item.value}`, $event)" v-ripple>
                                            {{ item.name }} <span class="icon" v-if="item.value != 'state'"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="isGetingItems">
                                    <tr v-for="n in APIList.preSize" :key="n">
                                        <td v-for="na in 6" :key="na" style="padding:0 1rem;">
                                            <ContentLoader :speed="1" width="100" :height="12">
                                                <rect x="15" y="3" rx="3" ry="3" :width="70" :height="7" />
                                            </ContentLoader>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="APIList.list.length === 0 && !isGetingItems">
                                    <tr>
                                        <td colspan="6">{{ $t('common.msg.search.noResult') }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(item, index) in APIList.list" :key="index" @click="$router.push({name: 'transferInfo',params: { no: item.no }})">
                                        <td>{{ item.crtDtStr }}</td>
                                        <td>{{ item.patientSn }}</td>
                                        <td>{{ item.patientNm }}</td>
                                        <td>{{ item.requestNm }} ({{ item.requestId }})</td>
                                        <td>{{ item.transfereeNm }} ({{ item.transfereeId }})</td>
                                        <td><v-chip :class="item.state&&getStateChipClass(item.state)">{{ translateState(item.state) }}</v-chip></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
import API_REQUEST_LIST from '@/API/transferRequest/list';
import { ContentLoader } from 'vue-content-loader'

export default {
    inject: ["setRightSideHidden"],
    components: {
        ContentLoader,
    },
    props: {},
    data() {
        return {
            isGetingItems: false,
            APIList: {
                APIReq: {
                    pageNo: 1,
                    pageSize: 300,
                    searchType: 'requestNm',
                    searchQuery: '',
                    onlyReqst: false,
                    isPagingList: false,
                },
                list: [],
                total: 0,
                preSize: 1,
            },
            sortBy: [],
            loading: false,
            reachedEnd: false,
            srhBoxFocus: '',
        }
    },
    watch: {
        $route(to) {
            if (to.name == 'transferManage') {
                this.getList();
            }
        },
    },
    created() { },
    mounted() {
        this.$store.dispatch("page/setSearchType", this.APIList.APIReq.searchType);
        this.APIList.APIReq.onlyReqst = this.$store.getters['page/onlyReqst'];
        this.APIList.APIReq.searchType = this.$store.getters['page/searchType'];
        this.APIList.APIReq.searchQuery = this.$store.getters['page/searchQuery'];
        this.setRightSideHidden(false)
        this.getList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        this.initSearch()
    },
    methods: {
        search() {
            if (!this.APIList.APIReq.searchType) {
                this.showPopup(this.$t("common.msg.search.type"));
                return false;
            }
            this.APIList.APIReq.pageNo = 1;
            this.scrollToTop();
            this.getList();
        },
        async getList() {
            if (this.isGetingItems) {
                return;
            }
            this.APIList.list=[];
            this.isGetingItems = true;
            let res = await API_REQUEST_LIST.request(this.APIList.APIReq.pageNo, this.APIList.APIReq.pageSize,
                this.APIList.APIReq.searchType, this.APIList.APIReq.searchQuery, this.APIList.APIReq.onlyReqst,this.sortBy);
            if (res.isSuccess) {
                this.APIList.list = res.list;
                this.APIList.total = res.total;
                this.APIList.preSize = Math.max(1, this.APIList.list.length);
                this.APIList.preSize = Math.min(13, this.APIList.list.length);
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.$store.dispatch("page/setSearchType", this.APIList.APIReq.searchType);
            this.$store.dispatch("page/setSearchQuery", this.APIList.APIReq.searchQuery);
            this.$store.dispatch("page/setOnlyReqst", this.APIList.APIReq.onlyReqst);
            this.isGetingItems = false;
        },
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;
            const bottomOffset = 900;
            if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
                this.loadMoreList();
            }
        },
        async loadMoreList() {
            if (this.loading || this.reachedEnd) return;
            if(this.APIList.list.length < this.APIList.APIReq.pageSize) return;
            this.loading = true;
            const nextPage = this.APIList.APIReq.pageNo + 1;
            let res = await API_REQUEST_LIST.request(this.APIList.APIReq.pageNo, this.APIList.APIReq.pageSize,
                this.APIList.APIReq.searchType, this.APIList.APIReq.searchQuery, this.APIList.APIReq.onlyReqst,this.sortBy);
            if (res.isSuccess) {
                this.APIList.list = [...this.APIList.list, ...res.list];
                this.APIList.APIReq.pageNo = nextPage;
                if (res.list.length < this.APIList.APIReq.pageSize) {
                    this.reachedEnd = true;
                }
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.loading = false;
        },
        scrollToTop() {
            this.$refs.scrollContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            this.reachedEnd = false;
        },
        setSortType(item, event) {
            if(item === 'state') return;
            const classNames = ['up', 'down'];
            let order = '';
            let targetElement = event.target;
            if (targetElement.className.includes('icon')) targetElement = targetElement.closest('th');
            const isUpClass = classNames.includes(targetElement.className);
            if (isUpClass) {
                order = targetElement.className === 'up' ? 'desc' : 'init';
                targetElement.className = targetElement.className === 'up' ? 'down' : 'default';
            } else {
                order = 'asc';
                targetElement.className = 'up';
            }

            const existingIndex = this.sortBy.findIndex(obj => obj.key === item);
            if (existingIndex !== -1) {
                if (order === 'init') {
                    this.sortBy.splice(existingIndex, 1);
                } else {
                    this.sortBy[existingIndex].order = order;
                }
            } else {
                this.sortBy.push({ key: item, order: order });
            }
            this.APIList.APIReq.pageNo = 1;
            this.scrollToTop();
            this.getList();
        },
        chgOnlyReqst(){
            if(this.APIList.APIReq.onlyReqst == null) this.APIList.APIReq.onlyReqst = false
            this.APIList.APIReq.pageNo = 1; 
            this.scrollToTop();
            this.getList(); 
        },
        getStateChipClass(state) {
            return state.toLowerCase(); 
        }
    },
    computed: {
        searchItems() {
            return [
                { name: this.$t('page.transfer.reqUser'), value: 'requestNm' },
                { name: this.$t('page.transfer.transfee'), value: 'transfereeNm' },
                { name: this.$t('page.transfer.patientSn'), value: 'patientSn'},
                { name: this.$t('page.transfer.patientNm'), value: 'patientNm'},
            ]
        },
        header(){
            return [
                { name: this.$t('page.transfer.reqDt'), value: 'crtDt'},
                { name: this.$t('page.transfer.patientSn'), value: 'patientSn'},
                { name: this.$t('page.transfer.patientNm'), value: 'patientNm'},
                { name: this.$t('page.transfer.reqUser'), value: 'requestNm'},
                { name: this.$t('page.transfer.transfee'), value: 'transfereeNm'},
                { name: this.$t('page.transfer.status'), value: 'state'},
            ]
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tblScroll{
    max-height: 655px;
    .tbl{
        border-bottom: 0;
        td{
            .buttonRow{display: flex; justify-content: space-between; align-items: center;}
        }
    }
}

.box-container{width: 100% !important;}
.conBox .divBox{margin-top:30px;}
.conBox>p{padding-bottom: 12px; text-align: right; color: rgba(236, 240, 241); font-size: 14px;}
.conBox>p span{font-weight: bold;}

.srhTop .srhBox{width: 100%;}

.pointColor{
    color: var(--color-point);
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

::v-deep .v-input{margin-top: 0; padding-top: 0;}
::v-deep .v-input__control{flex-direction: inherit; flex-grow: inherit; flex-wrap: nowrap;}
::v-deep .v-input__slot{justify-content: center;}
::v-deep .theme--light.v-label{color: rgba(0, 0, 0, 0.87); font-size: 1.4rem; display: flex; flex:none}

.conBox .tbl tbody tr:hover{cursor: pointer;}

.divBox .checkboxArea{
    margin-bottom: 12px; display: flex; justify-content: flex-end;
    >div{display: flex; align-items: center;}
    .labelText{color:  #fff; margin-right: 7px; font-size: 14px;}
    ::v-deep .v-input__slot{justify-content: flex-end;}
    ::v-deep .theme--light.v-label{color: #fff; font-size: 1.4rem; display: flex; flex:none}
    ::v-deep .theme--light.v-icon{color: rgba(255, 255, 255, 0.75)}
}

// 상태값 css
.v-chip.reqst{background-color:#FFF9F2; color: #FFA22E;}
.v-chip.confm{background-color:#EBFAFC; color: #00BAD3;}
.v-chip.cancl{background-color:#F5F5F5; color: #666;}
.v-chip.return{background-color:#FFF1F3; color: #FF2D52;}
</style>

